<template>
  <!-- eslint-disable -->
  <b-card>
    <div>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <!-- Field: Full Name -->
            <b-col cols="12" md="4">
              <b-form-group label="Nombres" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="userData.name"
                >
                  <b-form-input
                    @keypress="isLetter"
                    placeholder="Nombres"
                    id="name"
                    v-model="userData.name"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo nombres es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Apellidos" label-for="last_name">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="userData.last_name"
                >
                  <b-form-input
                    @keypress="isLetter"
                    id="last_name"
                    placeholder="Apellidos"
                    v-model="userData.last_name"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo apellidos es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Email -->
            <b-col cols="12" md="4">
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  rules="requeridoE|correo"
                  name="userData.email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    type="email"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                    :class="{ 'border border-danger': errors.length > 0 }"
                  />
                  <small v-if="errors.length > 0" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Role -->
            <b-col cols="12" md="4">
              <b-form-group label="Rol" label-for="user-role">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="userData.role"
                >
                  <v-select
                    v-model="userData.role"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="user-role"
                    placeholder="Seleccione un rol"
                    :state="errors.length > 0 ? false : null"
                    :class="{
                      'border border-danger rounded': errors.length > 0,
                    }"
                  />
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo rol es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Company -->
            <b-col cols="12" md="4">
              <b-form-group label="Empresa" label-for="user-company">
                <v-select
                  v-model="userData.company_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companyOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-company"
                  placeholder="Ningun empresa seleccionada"
                />
              </b-form-group>
            </b-col>
            <!-- Field: PASSWORD -->
            <b-col cols="12" md="4">
              <b-form-group label="Contraseña" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="userData.password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{
                      'border border-danger rounded': errors.length > 0,
                    }"
                  >
                    <b-form-input
                      :type="passwordType"
                      id="password"
                      autocomplete="new-password"
                      v-model="userData.password"
                      placeholder="Contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger"
                    >El campo empresa es requerido</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
            :disabled="isDisabled"
          >
            <b-spinner v-if="isDisabled" small />
            <span v-if="isDisabled" class="px-1">guardando...</span>
            <span v-else>Guardar cambios</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            type="reset"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Resetear
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, requeridoE, correo } from "@validations";
import UserService from "@/services/UserService";
import CompanyService from "@/services/CompanyService";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      userData: {
        name: "",
        last_name: "",
        email: "",
        role: "",
        company_id: null,
        password: "",
      },
      isDisabled: false,
      passwordType: "password",
      required,
      requeridoE,
      correo,
      currentPage: 1,
      showEntrie: 100,
      sort: "id",
      order: "asc",
      roleOptions: [
        { label: "Admin", value: "admin" },
        { label: "Contratista", value: "contratista" },
        { label: "Regulador", value: "regulador" },
        { label: "Jefe de Area", value: "jefe_area" },
      ],
      companyOptions: [],
    };
  },
  computed: {
    passwordToggleIconRetype() {
      return this.passwordType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    this.getData();
    console.log("companyOptions", this.companyOptions);
  },
  methods: {
    togglePasswordRetype() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    isLetter(event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        event.preventDefault();
      }
    },
    async getData() {
      const url = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`;
      const resp = await CompanyService.getCompanys(url, this.$store);
      if (resp) {
        resp.rows.forEach((element) => {
          this.companyOptions.push({
            label: element.business_name,
            value: element.id,
          });
        });
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.simpleRules.validate();
      if (!isValid) {
        console.log("NO VALIDADO");
      } else {
        this.isDisabled = true;
        const resp = await UserService.saveUser(this.userData, this.$store);
        console.log("RESP ADD USER", resp);
        if (resp.status) {
          this.$swal({
            title: "Añadido",
            text: "El usuario ha sido registrado",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push("/users/list");
        } else {
          this.$swal({
            title: "Advertencia!",
            text: resp.msg,
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        this.isDisabled = false;
      }
    },
  },
};
</script>

<style></style>
